const Text = {
  variants: {
    "campaign-activity-table-header": {
      color: "gray.3",
    },
    "blade-headline": {
      fontSize: ["clamp(1em, 5.5vw, 4em);", "clamp(2em, 4vw, 5em);"],
      fontWeight: "700",
      fontFamily: "Poppins",
      lineHeight: "100%",
      maxW: "100%",
    },
    "blade-body": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: ["clamp(0.525em, 3vw, 1em);", "clamp(1em, 1.2vw, 2.5em);"],
      lineHeight: "100%",
    },
    "brand-blade-page-number": {
      _hover: { cursor: "pointer" },
      fontWeight: "700",
      fontFamily: "Poppins",
      fontSize: "clamp(0.5em, 1.5vw, 1.5em);",
      color: "soft_black",
    },
    "creator-blade-page-number": {
      _hover: { cursor: "pointer" },
      fontWeight: "700",
      fontFamily: "Poppins",
      fontSize: "clamp(0.5em, 1.5vw, 1.5em);",
    },
  },
};

export default Text;
