const colors = {
  twitch_purple: "#8650F6",
  nfluence_blue: "#1100FF",
  blue_highlight: "#0047FF",
  nfluence_pink: "#f739aa",
  nfluence_pink2: "#FC3BA4",
  nfluence_purple_hover: "#3F0F59",
  nfluence_purple_highlight: "#A700FF",
  nfluence_purple: "#9215e7",
  nfluence_purple2: "#2E22AA",
  nfluence_purple3: "#815BD5",
  nfluence_purple4: "#8A1EC3",
  nfluence_purple5: "#30007A",
  nfluence_purple6: "#815BD5",
  nfluence_purple7: "#C6AEFD",
  nfluence_yellow: "#ffc107",
  nfluence_green: "#00FF00",
  nfluence_green2: "#A8C007",
  nfluence_green3: "#00FD3B",
  nfluence_red: "#FF0000",
  nfluence_peach: "#ff9966",
  brand_purple1: "#300878",
  brand_purple3: "#300378",
  brand_purple2: "#815fd3",
  status_green: "#0C8729",
  creator_green: "#00fb4b",
  soft_black: "#414042",
  forest_green: "#228b22",
  black: "#000000",
  white: "#FFFFFF",
  nav_gray: "#8d879e",
  auth_purple: "#1C1434",
  placeholder_gray: "#49494a",
  purple: {
    1: "#825ad6",
  },
  gray: {
    2: "#4f4f4f",
    3: "#828282",
    4: "#BDBDBD",
    5: "#E0E0E0",
    6: "#F2F2F2",
    7: "#DADADA",
    500: "#828282",
  },
  lightgray: {
    500: "#e0e0e0",
  },

  custom_red: {
    500: "#FF0000",
  },
  custom_purple: {
    500: "#8A1EC3",
  },
  custom_purple2: {
    500: "#815BD5",
  },
  custom_green: {
    500: "#00FF00",
  },
  custom_blue: {
    500: "#1100FF",
  },
  custom_pink: {
    500: " #FC3BA4",
  },
};

export default colors;
