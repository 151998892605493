import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface CampaignState {
  grid: boolean;
  selectedCampaign: object;
  selectedImageAssets: Array<object>;
  selectedImageIDS: Array<number>;
  selectedImageAssetsID: Array<object>;
  selectedUnderlayImage: Array<object>;
  selectedUnderlayID: Array<number>;
  selectedUnderlayImageID: Array<object>;
  selectedSortOption: string;
  selectedCampaignFilters: Array<string>;
  filterString: string;
}

const initialState: CampaignState = {
  grid: true,
  selectedCampaign: {},
  //Assets
  selectedImageAssets: [],
  selectedImageIDS: [],
  selectedImageAssetsID: [],
  //Underlay
  selectedUnderlayImage: [],
  selectedUnderlayID: [],
  selectedUnderlayImageID: [],
  selectedSortOption: null,
  selectedCampaignFilters: [],
  filterString: "",
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    toggleLayout: (state, action) => {
      state.grid = !state.grid;
    },
    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    setSelectedImageAssets: (state, action) => {
      state.selectedImageAssets = action.payload;
    },
    setSelectedImageIDS: (state, action) => {
      state.selectedImageIDS = action.payload;
    },
    setSelectedImageAssetsIDS: (state, action) => {
      state.selectedImageAssetsID = action.payload;
    },
    setSelectedUnderlayImage: (state, action) => {
      state.selectedUnderlayImage = action.payload;
    },
    selectedUnderlayID: (state, action) => {
      state.selectedUnderlayID = action.payload;
    },
    setSelectedUnderlayImageID: (state, action) => {
      state.selectedUnderlayImageID = action.payload;
    },
    setSortOption: (state, action) => {
      state.selectedSortOption = action.payload;
    },
    setSelectedCampaignFilters: (state, action) => {
      state.selectedCampaignFilters = action.payload;
    },
    setFilterString: (state, action) => {
      state.filterString = action.payload;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
  },
});

//Select slice name to get access to states
export const campaignSelector = (state: { campaign: CampaignState }) =>
  state.campaign;

export const {
  toggleLayout,
  setSelectedCampaign,
  setSelectedImageAssets,
  setSelectedUnderlayImage,
  setSelectedImageIDS,
  selectedUnderlayID,
  setSelectedImageAssetsIDS,
  setSelectedUnderlayImageID,
  setSortOption,
  setSelectedCampaignFilters,
  setFilterString,
} = campaignSlice.actions;

export default campaignSlice.reducer;
