import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export interface Call {
  id: number;
  campaign_budget: string;
  campaign_payment_type: string;
  campaign_coupon_code: string;
  campaign_product_description: string;
  campaign_product_image: string;
  minimum_concurrent_viewers: number;
  campaign_type: string;
  max_participants: number;
  total_influencer_campaigns: number;
  name: string;
  campaign_logo: string;
  created_on: string;
  start_date: string;
  end_date: string;
  status: string;
  slug: string;
}

type CallResponse = Call[];

export const IsProduction = process.env.NODE_ENV === "production";
export const isDebug = process.env.NEXT_PUBLIC_DEBUG_ENV === "debug";

export const base_url_debug = "http://127.0.0.1:8000/api";
export const base_url_develop =
  "https://development.influencer-platform.com/api";
export const base_url_production =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "master"
    ? // ? `https://influencer-platform.com/api`
      `https://development-eb.influencer-platform.com/api`
    : // : process.env.NEXT_PUBLIC_VERCEL_URL === "develop"
      `https://development.influencer-platform.com/api`;
// : `https://development-eb.influencer-platform.com/api`;

export const companyAPI = createApi({
  reducerPath: "companyAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: IsProduction
      ? base_url_production
      : isDebug && !IsProduction
      ? base_url_debug
      : base_url_develop,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      } else {
        console.log("no headers");
      }
      return headers;
    },
  }),
  tagTypes: ["Call"],
  endpoints: (builder) => ({
    // Get Company information
    getCompanyId: builder.mutation({
      query: (id: string) => ({
        url: `/users/${id}/get_company/`,
        method: "GET",
      }),
    }),
    // Create a new company user
    postNewCompanyUser: builder.mutation<Call, object>({
      query: (body: object) => ({
        url: `/users/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Call"],
    }),
    // Get a list of company influencers (Old Version)
    // getCompanyInfluencers: builder.query({
    //   query: (company_id: string) => ({
    //     url: `/getcompanyinfluencers/${company_id}?p=1&page_size=3`,
    //     method: "GET",
    //   }),
    // }),
    // Get a list of company influencers
    getCompanyInfluencers: builder.query<
      any,
      { company_id: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { company_id, page_number, page_size } = arg;
        // console.log(arg);
        // console.log(arg.company_id);
        return {
          url: `/getcompanyinfluencers/${company_id}?p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    // Get a specific of company influencer
    getCompanyInfluencerInfo: builder.query({
      query: (influencer_id: string) => ({
        url: `/getsinglecompanyinfluencer/${influencer_id}`,
        method: "GET",
      }),
    }),
    // Get a list of company users
    getTeamMembers: builder.query({
      query: (company_id: string) => ({
        url: `/company/${company_id}/get_company_users/`,
        method: "GET",
      }),
    }),
    // Get the current user information
    getUserInfo: builder.query({
      query: (id: string) => ({
        url: `/users/${id}/`,
        method: "GET",
      }),
    }),
    //Get a list of all campaigns
    getAllCampaigns: builder.query<CallResponse, void>({
      query: () => "/companycampaign/",
      providesTags: ["Call"],
    }),
    //Get a list of all in progress influencer campaigns
    getAllSubmittedCampaigns: builder.query({
      query: (company_id: string) => ({
        url: `/company/${company_id}/get_all_in_progress_inf_campaigns/`,
        method: "GET",
      }),
    }),
    //Get a list of all validated influencer campaigns
    getAllValidatedCampaigns: builder.query({
      query: (company_id: string) => ({
        url: `/company/${company_id}/get_all_validated_inf_campaigns/`,
        method: "GET",
      }),
    }),
    //Get a list of all disputed influencer campaigns
    getAllDisputedCampaigns: builder.query({
      query: (company_id: string) => ({
        url: `/companydisputedcampaigns/${company_id}`,
        method: "GET",
      }),
    }),
    //Accept or reject a disputed influencer claim
    putEditDispute: builder.mutation<Call, any>({
      query: (info) => ({
        url: `/disputedcampaigns/${info.disputed_id}/`,
        method: "PATCH",
        body: info,
      }),
      invalidatesTags: ["Call"],
    }),
    // Get a specific campaign information
    getCampaignInfo: builder.query({
      query: (id: string) => ({
        url: `/companycampaign/${id}/`,
        method: "GET",
      }),
    }),
    // Create a new campaign
    postNewCampaign: builder.mutation<Call, object>({
      query: (body: object) => ({
        url: `/companycampaign/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Call"],
    }),
    // Edit a campaign
    putEditCampaign: builder.mutation<Call, any>({
      query: (info) => ({
        url: `/companycampaign/${info.slug}/`,
        method: "PATCH",
        body: info,
      }),
      invalidatesTags: ["Call"],
    }),
    // Delete a campaign
    deleteCampaign: builder.mutation<Call, number>({
      query: (id: number) => ({
        url: `/companycampaign/${id}/`,
        method: "DELETE",
        id,
      }),
      invalidatesTags: ["Call"],
    }),
    // Get a list of all created brands
    getAllBrands: builder.query({
      query: (id: number) => ({
        url: `/company/${id}/get_brands/`,
        method: "GET",
        providesTags: (result) =>
          result ? result.map(({ id }) => ({ type: "Call", id })) : ["Call"],
      }),
    }),
    // Create a new brand
    postNewBrand: builder.mutation<Call, object>({
      query: (body: object) => ({
        url: `/companybrand/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Call"],
    }),
    // Edit a brand
    putEditBrand: builder.mutation<Call, any>({
      query: (info) => ({
        url: `/companybrand/${info.id}/`,
        method: "PATCH",
        body: info,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Call", id }],
    }),
    // Get a specfic brand information
    getBrandInfo: builder.query({
      query: (id: number) => ({
        url: `/companybrand/${id}/`,
        method: "GET",
      }),
    }),
    //Edit brand callouts
    postUpdateCallouts: builder.mutation<Call, any>({
      query: (info) => ({
        url: `/updatecallouts/${info.brand_id}/`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Call"],
    }),
    //Edit brand assets
    postUpdateAssets: builder.mutation<Call, any>({
      query: (info) => ({
        url: `/updateassets/${info.brand_id}/`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Call"],
    }),
    postManuallyValidate: builder.mutation<Call, any>({
      query: (id: number) => ({
        url: `/manuallyvalidate/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Call"],
    }),
    postManuallyInValidate: builder.mutation<Call, any>({
      query: (id: number) => ({
        url: `/manuallyinvalidate/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Call"],
    }),
    //Get influencer total earnings
    getInfluencerTotalEarnings: builder.query({
      query: (id: string) => ({
        url: `/totalearnings/${id}`,
        method: "GET",
      }),
    }),
    postRequestDemo: builder.mutation<Call, object>({
      query: (body: object) => ({
        url: `/personalized-demo/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Call"],
    }),
    postContactUs: builder.mutation<Call, object>({
      query: (body: object) => ({
        url: `/contact-us/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Call"],
    }),
    checkValidUser: builder.query<Call, void>({
      query: () => ({
        url: `/check-user-location/`,
        method: "GET",
      }),
    }),
  }),
  refetchOnFocus: true,
  keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: true,
});

// Export hooks for usage in functional components
export const {
  //Company Hooks
  useGetCompanyIdMutation,
  usePostNewCompanyUserMutation,
  //User hooks
  useGetTeamMembersQuery,
  useGetUserInfoQuery,
  //Influencer hooks
  useLazyGetCompanyInfluencersQuery,
  useGetCompanyInfluencerInfoQuery,
  //Campaign Hooks
  useGetAllCampaignsQuery,
  useGetAllSubmittedCampaignsQuery,
  useGetAllValidatedCampaignsQuery,
  useGetAllDisputedCampaignsQuery,
  usePutEditDisputeMutation,
  useLazyGetCampaignInfoQuery,
  usePostNewCampaignMutation,
  usePutEditCampaignMutation,
  useDeleteCampaignMutation,
  usePostManuallyValidateMutation,
  usePostManuallyInValidateMutation,
  //Brands
  useGetAllBrandsQuery,
  useGetBrandInfoQuery,
  usePutEditBrandMutation,
  usePostNewBrandMutation,
  usePostUpdateAssetsMutation,
  usePostUpdateCalloutsMutation,
  //Payment
  useGetInfluencerTotalEarningsQuery,
  //Contact
  usePostRequestDemoMutation,
  usePostContactUsMutation,
  // Check Valid User
  useLazyCheckValidUserQuery,
} = companyAPI;
