import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import backendAPI from "@/api/backendAPI";
import _ from "lodash";

export interface BrandState {
  brand: object;
  searchTerm: string;
}

const initialState: BrandState = {
  brand: {},
  searchTerm: "",
};

const brandSlice = createSlice({
  name: "Brand",
  initialState,
  reducers: {
    setSearchTerm(state, { payload }) {
        state.searchTerm = payload;
      },
  },
  extraReducers: (builder) => {},
});

//Select slice name to get access to states
export const BrandSelector = (state: { brand: BrandState }) => state.brand;

export const { setSearchTerm  } = brandSlice.actions;

export default brandSlice.reducer;
