import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import backendAPI from "@/api/backendAPI";
import _ from "lodash";

export interface UserData {
  influencer_profile: object;
}

export interface UserState {
  user: any;
  paypal: object;
}

const initialState: UserState = {
  user: {},
  paypal: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setPaypal(state, { payload }) {
      state.paypal = payload;
    },
    updateInfluencerUserProfile(state, action) {
      state.user.influencerprofile = action.payload;
    },
    updateUserProfile(state, action) {
      state.user.influencerprofile.user_profile = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

//Select slice name to get access to states
export const userSelector = (state: { user: UserState }) => state.user;

export const {
  setUser,
  setPaypal,
  updateInfluencerUserProfile,
  updateUserProfile,
} = userSlice.actions;

export default userSlice.reducer;
