import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendAPI from "@/api/backendAPI";

export interface AuthState {
  token: string;
  company_id: string;
  influencer_id: string;
  last_url: string;
}

const initialState: AuthState = {
  token: "",
  company_id: "",
  influencer_id: "",
  last_url: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state, { payload }) {
      state.token = payload;
    },
    setCompanyId(state, { payload }) {
      state.company_id = payload;
    },
    setInfluencerId(state, { payload }) {
      state.influencer_id = payload;
    },
    setLast(state, { payload }) {
      state.last_url = payload;
    },
  },
  extraReducers: (builder) => {},
});

//Select slice name to get access to states
export const authSelector = (state: { auth: AuthState }) => state.auth;

export const {
  setCredentials,
  setCompanyId,
  setInfluencerId,
  setLast,
} = authSlice.actions;

//export single reducers
export default authSlice.reducer;
