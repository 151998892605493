const Tooltip = {
  variants: {
    payment_status: {
      color: "#FFF",
      borderRadius: "15px",
      bg: "nfluence_purple3",
      fontFamily: "Roboto Condensed",
      fontWeight: "700",
      fontSize: "16px",
      placement: "top",
      py: "5px",
      pl: "10px",
    },
  },
};

export default Tooltip;
