const title = "Nfluence - Streamer Marketplace";
const description =
  "Nfluence helps streamers connect with brands and make money by monetizing their social influence. Nfluence - Powered by Stream Engine.";

const SEO = {
  title,
  description,
  canonical: "https://nfluence.com/",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://nfluence.com/",
    title,
    description,
    images: [
      {
        url: "https://nfluence.com/images/logo_small.png",
        alt: title,
        width: 800,
        height: 600,
      },
      {
        url: "https://nfluence.com/images/logo_large.png",
        alt: title,
        width: 1200,
        height: 700,
      },
    ],
  },
  twitter: {
    handle: "@nfluence",
    site: "@nfluence",
    cardType: "summary_large_image",
  },
};

export default SEO;
