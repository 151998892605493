import breakpoints from "@/theme/default-theme/foundations/breakpoints";
import colors from "@/theme/default-theme/foundations/colors";
import { extendTheme } from "@chakra-ui/react";
// import Button from "@/theme/roccat-theme/components/button";
import Text from "@/theme/default-theme/components/text";
import Tooltip from "@/theme/default-theme/components/tooltip";

const theme = extendTheme({
  colors,
  breakpoints,
  components: {
    Text,
    Tooltip,
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export default extendTheme(theme);
