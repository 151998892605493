import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import logger from "redux-logger";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import campaignSlice from "./slices/campaignSlice";
import { rtkQueryErrorLogger } from "./errorMiddleware";
import { influencerAPI } from "./services/influencer_api";
import { companyAPI } from "./services/company_api";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["companyAPI", "influencerAPI", "campaign"],
  whitelist: ["auth", "user"],
  //
};

// whitelist: ["auth", "user"],

const persistedReducer = persistReducer(persistConfig, rootReducer);

//have to spread to get the other built in middle-ware to use a new one
const middleware = [
  ...getDefaultMiddleware({
    // serializableCheck: false
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(influencerAPI.middleware, companyAPI.middleware),
  rtkQueryErrorLogger,
  // logger,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "development" ? false : true,
});

export type RootState = ReturnType<typeof store.getState>;
