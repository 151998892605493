import { MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit";
import Router from "next/router";

/**
 * Log a warning and show a toast!
 */

export const rtkQueryErrorLogger = (store) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    // Router.replace("/influencer/dashboard/login");
    Router.replace("/influencer/dashboard/early-access/login");
  }

  return next(action);
};
