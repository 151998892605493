import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import backendAPI from "@/api/backendAPI";
import _ from "lodash";

export interface InfluencerState {
  influencer: object;
  facebookInfo: object;
  searchTerm: string;
  currentInfluencerOverviewPageNum: number;
  currentInfluencerOverviewPageSize: number;
  isAuthenticated: boolean;
  facebookButtonClicked: boolean;
}

const initialState: InfluencerState = {
  influencer: {},
  facebookInfo: {},
  searchTerm: "",
  currentInfluencerOverviewPageNum: 1,
  currentInfluencerOverviewPageSize: 10,
  isAuthenticated: false,
  facebookButtonClicked: false,
};

const influencerSlice = createSlice({
  name: "Influencer",
  initialState,
  reducers: {
    setFacebookInfo(state, action) {
      state.facebookInfo = action.payload;
    },
    setSearchTerm(state, { payload }) {
      state.searchTerm = payload;
    },
    setCurrentInfluencerOverviewPageNumber(state, { payload }) {
      state.currentInfluencerOverviewPageNum = payload;
    },
    setCurrentInfluencerOverviewPageSize(state, { payload }) {
      state.currentInfluencerOverviewPageSize = payload;
    },
    setisAuthenticated(state, { payload }) {
      state.isAuthenticated = payload;
    },
    setFacebookButtonClicked(state, { payload }) {
      state.facebookButtonClicked = payload;
    },
  },
  extraReducers: (builder) => {},
});

//Select slice name to get access to states
export const influencerSelector = (state: { influencer: InfluencerState }) =>
  state.influencer;

export const {
  setFacebookInfo,
  setSearchTerm,
  setCurrentInfluencerOverviewPageNumber,
  setCurrentInfluencerOverviewPageSize,
  setisAuthenticated,
  setFacebookButtonClicked,
} = influencerSlice.actions;

export default influencerSlice.reducer;
