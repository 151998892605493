import "../styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "@brainhubeu/react-carousel/lib/style.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "../redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "focus-visible/dist/focus-visible";
import "react-tagsinput/react-tagsinput.css";
import "react-custom-scroll/dist/customScroll.css"; // requires a loader
import Head from "next/head";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";

import theme from "@/theme/default-theme/theme";

export const persistor = persistStore(store);

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta title="Nfluence Streamer Initiative" />
      </Head>
      <DefaultSeo {...SEO} />
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <Component {...pageProps} />
          </ChakraProvider>
        </PersistGate>
      </ReduxProvider>
    </>
  );
};

export default MyApp;
