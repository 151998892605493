import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export interface Influencer {
  id: number;
  token: any;
  user: any;
  influencerprofile: any;
  response: any;
}

type InfluencerResponse = Influencer[];

export const IsProduction = process.env.NODE_ENV === "production";
export const isDebug = process.env.NEXT_PUBLIC_DEBUG_ENV === "debug";

export const base_url_debug = "http://127.0.0.1:8000/api";
export const base_url_develop =
  "https://development.influencer-platform.com/api";
export const base_url_production =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "master"
    ? // ? `https://influencer-platform.com/api`
      `https://development-eb.influencer-platform.com/api`
    : // : process.env.NEXT_PUBLIC_VERCEL_URL === "develop"
    process.env.NEXT_PUBLIC_BRANCH === "preview"
    ? "http://127.0.0.1:8000/api"
    : `https://development.influencer-platform.com/api`;
// : `https://development-eb.influencer-platform.com/api`;

export const influencerAPI = createApi({
  reducerPath: "influencerAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: IsProduction
      ? base_url_production
      : isDebug && !IsProduction
      ? base_url_debug
      : base_url_develop,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        console.log("no headers");
      }
      return headers;
    },
  }),
  tagTypes: ["Influencer"],
  endpoints: (builder) => ({
    // Sign up Influencer
    signUp: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/register/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    // Waitlist signup
    waitlistSignUp: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/enter-waitlist`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    // Sign In Influencer
    logIn: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/signin/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    // Verify influencer email
    verifyEmail: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/verifiy/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    verifyTwitch: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/twitch/connect/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    verifyTikTok: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/tiktok/connect/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    verifyInstagram: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/instagram/connect/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    verifyInstagramBusiness: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/instagram/connect/business`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    //Get all on-going influencer Campaigns
    getInfluencerInfo: builder.query({
      query: (id: string) => ({
        url: `/user_profiles/${id}`,
        method: "GET",
      }),
    }),
    // Edit influencer profile
    patchEditInfluencerProfile: builder.mutation<Influencer, any>({
      query: (info) => ({
        url: `/user_profiles/${info.id}/`,
        method: "PATCH",
        body: info,
      }),
      invalidatesTags: ["Influencer"],
    }),
    postEditInflucnerProfileWithPicture: builder.mutation<Influencer, any>({
      query: (info) => ({
        url: `/user_profiles/${info.id}/upload-profile-picture/`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Influencer"],
    }),
    // Connect influencer paypal information to platform
    // postPaypalAuthCode: builder.mutation<Influencer, object>({
    //   query: (body: object) => ({
    //     url: `connectuserpaypal/`,
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["Influencer"],
    // }),
    postPaypalAuthCode: builder.mutation<Influencer, any>({
      query: (body) => ({
        url: `user_profiles/${body.user_profile_id}/setup-paypal-aac/`,
        method: "POST",
        body: { code: body.code },
      }),
      invalidatesTags: ["Influencer"],
    }),
    //Get all on-going influencer Campaigns
    getAllOngoingCampaigns: builder.query<
      any,
      { id: string; query: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { id, query, page_number, page_size } = arg;
        // console.log(arg);
        // console.log(arg.company_id);
        return {
          url: `/ongoingcampaigns/${id}?${query}${
            query ? "&" : ""
          }p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    //Get influencer total earnings
    getInfluencerTotalEarnings: builder.query({
      query: (id: string) => ({
        url: `/influencers/${id}/total-earnings/`,
        method: "GET",
      }),
    }),
    // Get specific campaign info for influencer
    getCampaignInfo: builder.query({
      query: (info) => ({
        url: `/influencer/${info.influencer_id}/companycampaign/${info.slug}`,
        method: "GET",
        params: info,
      }),
    }),
    // Get all Saved influencer campaigns
    getAllSavedCampaigns: builder.query<
      any,
      { id: string; query: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { id, query, page_number, page_size } = arg;
        return {
          url: `/savedcampaigns/${id}?${query}${
            query ? "&" : ""
          }p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    // Get all avaliable influencer campaigns

    getAllAvailableCampaigns: builder.query<
      any,
      { id: string; query: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { id, query, page_number, page_size } = arg;
        return {
          url: `/availablecampaigns/${id}?${query}${
            query ? "&" : ""
          }p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    // Get all history influencer campaigns
    getAllHistoryCampaigns: builder.query<
      any,
      { id: string; query: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { id, query, page_number, page_size } = arg;
        return {
          url: `/influencercampaignhistory/${id}/?${query}${
            query ? "&" : ""
          }p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    // Move to history for influencer
    postMoveToHistory: builder.mutation({
      query: (info) => ({
        url: `/influencercampaignhistory/move-to-history/`,
        method: "POST",
        body: info,
      }),
    }),
    // Get available rewards for influencer
    getInfluencerAvailableRewards: builder.query({
      query: (id: string) => ({
        url: `/influencers/${id}/available-rewards/`,
        method: "GET",
      }),
    }),
    // Get all reward history for campaigns
    getInfluencerRewardsHistory: builder.query<
      any,
      { id: string; query: string; page_number: number; page_size: number }
    >({
      query: (arg) => {
        const { id, query, page_number, page_size } = arg;
        return {
          url: `/influencers/${id}/rewards-history?${query}${
            query ? "&" : ""
          }p=${page_number}&page_size=${page_size}`,
          // params: { company_id, page_number },
          method: "GET",
        };
      },
    }),
    // Accept Campaign for influencer
    postAcceptCampaign: builder.mutation({
      query: (info) => ({
        url: `/acceptcampaign/${info.influencer_id}/companycampaign/${info.campaign_type}/${info.slug}`,
        method: "POST",
        body: info,
      }),
    }),
    // Save Campaign for influencer
    postSaveCampaign: builder.mutation({
      query: (info) => ({
        url: `/savecampaign/${info.influencer_id}/companycampaign/${info.slug}`,
        method: "POST",
        body: info,
      }),
    }),
    // Reject Campaign for influencer
    postRejectCampaign: builder.mutation({
      query: (info) => ({
        url: `/rejectcampaign/${info.influencer_id}/companycampaign/${info.slug}`,
        method: "POST",
        body: info,
      }),
    }),
    //Submit twitch clip
    submitCampaign: builder.mutation<Influencer, any>({
      query: (info) => ({
        url: `/submitcampaign/${info.influencer_campaign_id}`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Influencer"],
    }),
    //Dispute a influencer campaign
    postDisputeCampaign: builder.mutation<Influencer, any>({
      query: (info) => ({
        url: `/disputedcampaigns/`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Influencer"],
    }),
    forgotPassword: builder.mutation<Influencer, object>({
      query: (body: object) => ({
        url: `/auth/forget/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Influencer"],
    }),
    // reset password
    resetPassword: builder.mutation<Influencer, any>({
      query: (info) => ({
        url: `/reset/${info.token}/`,
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["Influencer"],
    }),
    //check user IP Address
    checkValidUser: builder.query<Influencer, void>({
      query: () => ({
        url: `/check-user-location/`,
        method: "GET",
      }),
    }),
    //submit w9 tax form
    submitW9Taxform: builder.mutation<any, { body: object; id: string }>({
      query: (arg) => {
        const { body, id } = arg;
        return {
          url: `submitw9taxform/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Influencer"],
    }),
  }),
  refetchOnFocus: true,
  keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: true,
});

// Export hooks for usage in functional components
export const {
  //Sign Up, Login, Verify
  useSignUpMutation,
  useWaitlistSignUpMutation,
  useLogInMutation,
  useVerifyEmailMutation,
  useVerifyTwitchMutation,
  useVerifyTikTokMutation,
  useVerifyInstagramMutation,
  useVerifyInstagramBusinessMutation,
  //Profile
  useGetInfluencerInfoQuery,
  useLazyGetInfluencerInfoQuery,
  usePatchEditInfluencerProfileMutation,
  usePostEditInflucnerProfileWithPictureMutation,
  //Payment
  useGetInfluencerTotalEarningsQuery,
  // Rewards
  useGetInfluencerRewardsHistoryQuery,
  useGetInfluencerAvailableRewardsQuery,
  // Accept/Save/Reject/Submit Campaign hooks
  usePostAcceptCampaignMutation,
  usePostSaveCampaignMutation,
  usePostRejectCampaignMutation,
  //Twitch hook
  useSubmitCampaignMutation,
  //Payment hooks
  usePostPaypalAuthCodeMutation,
  //Campaign hooks
  useGetAllOngoingCampaignsQuery,
  useLazyGetAllOngoingCampaignsQuery,
  useGetAllAvailableCampaignsQuery,
  useLazyGetCampaignInfoQuery,
  useGetAllSavedCampaignsQuery,
  useGetAllHistoryCampaignsQuery,
  // Move to History
  usePostMoveToHistoryMutation,
  //Dispute
  usePostDisputeCampaignMutation,
  //Reset Password
  useResetPasswordMutation,
  useForgotPasswordMutation,
  // Check Valid User
  useLazyCheckValidUserQuery,
  // Submit W9 Tax Form
  useSubmitW9TaxformMutation,
} = influencerAPI;
