import { createBreakpoints } from "@chakra-ui/theme-tools";

const browserContext = 16;

//Desk Only no mobile, tablets etc
const ViewportWidthMobile = 575; //phones -- RANGE: 0px-575px
// const ViewportWidthXSmall = 1366; //phones -- RANGE: 0px-575px
const ViewportWidthXSmall = 1200; //phones -- RANGE: 0px-575px
const ViewportWidthSmall = 1400; //tablets -- RANGE: 576px-768px
const ViewportWidthMedium = 1600; //medium laptops -- RANGE: 769px-1400px
const ViewportWidthLarge = 1920; //large monitors -- RANGE: 1600px-1920px
const ViewportWidthXLarge = 2560; //monitors -- RANGE: 1921px-2560px
// const ViewportWidth2XLarge = 3072; //ultrawide monitors -- RANGE: 2560px - 3072px
// const ViewportWidth2XLarge = 3072; //ultrawide monitors -- RANGE: 2560px - 3072px
const ViewportWidth2XLarge = 3440; //super ultrawide monitors -- RANGE: 3072-3440px+
const ViewportWidth3XLarge = 3840; //super ultrawide monitors -- RANGE: 3840px+

// const breakpointEm = {
//   base: `${ViewportWidthMobile / browserContext}em`,
//   sm: `${ViewportWidthXSmall / browserContext}em`,
//   md: `${ViewportWidthSmall / browserContext}em`,
//   lg: `${ViewportWidthMedium / browserContext}em`,
//   xl: `${ViewportWidthLarge / browserContext}em`,
//   "2xl": `${ViewportWidthXLarge / browserContext}em`,
//   "3xl": `${ViewportWidth2XLarge / browserContext}em`,
//   "4xl": `${ViewportWidth3XLarge / browserContext}em`,
// };

const breakpointEm = {
  sm: "30em", //Mobile
  md: "48em", //Mobile Landscape/Wide Phones (Maybe, not confirmed)
  lg: "62em", //Tablet
  xl: "80em", // Big Tablets/Desktop
  "2xl": "96em",
};

const breakpoints = createBreakpoints(breakpointEm);

export default breakpoints;
