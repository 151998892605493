import { combineReducers, AnyAction, Reducer } from "@reduxjs/toolkit";

//Slices
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import campaignReducer from "./slices/campaignSlice";
import influencerReducer from "./slices/influencerSlice";
import brandReducer from "./slices/brandSlice";

//Services

import { companyAPI } from "./services/company_api";
import { influencerAPI } from "./services/influencer_api";

const combinedReducer = combineReducers({
  //Slices
  auth: authReducer,
  user: userReducer,
  campaign: campaignReducer,
  influencer: influencerReducer,
  brand: brandReducer,

  //Services
  [companyAPI.reducerPath]: companyAPI.reducer,
  [influencerAPI.reducerPath]: influencerAPI.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;
// export type RootState = ReturnType<typeof store.getState>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "persist/PURGE") {
    // if (action.type === "auth/logOut") {
    // state = undefined;
    state = {} as RootState;
    // reset local storage
    localStorage.clear();
    // return combinedReducer(undefined, { type: undefined });
  }
  return combinedReducer(state, action);
};

export default rootReducer;
